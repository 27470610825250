import { ComponentRef, Injectable, Injector } from '@angular/core';
import { Overlay, OverlayConfig, OverlayRef } from '@angular/cdk/overlay';
import { ComponentPortal } from '@angular/cdk/portal';
import { Subject, tap } from 'rxjs';
import {
  OVERLAY_DIALOG_CONTROL,
  OVERLAY_DIALOG_DATA,
  OverlayData,
} from '@models/overlay';
import { ModalCloseResult, ModalCloseResultAction } from '@models/modals';

@Injectable({ providedIn: 'root' })
export class OverlayService {
  private overlayRef!: OverlayRef;
  private nestedOverlayRef!: OverlayRef | null;
  onClose$: Subject<ModalCloseResult> = new Subject<ModalCloseResult>();
  onCloseWithAction$: Subject<ModalCloseResultAction> =
    new Subject<ModalCloseResultAction>();
  private componentRef!: ComponentRef<any>;

  constructor(private overlay: Overlay) {}

  open(
    componentPortal: ComponentPortal<any>,
    data?: OverlayData,
    nested?: boolean,
  ): any {
    if (this.overlayRef && !nested) {
      this.close();
    }

    const overlayRef = this.overlay.create(this.getOverlayConfig());

    if (data) {
      const injector = this.createPortalInjector(data);
      componentPortal.injector = injector;
    }
    this.componentRef = overlayRef.attach(componentPortal);
    overlayRef
      .backdropClick()
      .pipe(tap(() => this.close()))
      .subscribe();

    if (nested) {
      this.nestedOverlayRef = overlayRef;
      return;
    }
    this.overlayRef = overlayRef;
  }

  createPortalInjector(overlayData: OverlayData): Injector {
    const { withTime, timeOnly, data, range, rangeAllowed, controlData, id } =
      overlayData;
    const injectionTokens = {
      providers: [
        {
          provide: OVERLAY_DIALOG_DATA,
          useValue: {
            withTime,
            timeOnly,
            data,
            range,
            rangeAllowed,
            controlData,
            id,
          },
        },
        {
          provide: OVERLAY_DIALOG_CONTROL,
          useValue: overlayData.control,
        },
      ],
    };

    return Injector.create(injectionTokens);
  }

  private getOverlayConfig(): OverlayConfig {
    const positionStrategy = this.overlay
      .position()
      .global()
      .centerHorizontally()
      .centerVertically();

    const overlayConfig = new OverlayConfig({
      scrollStrategy: this.overlay.scrollStrategies.block(),
      positionStrategy,
      hasBackdrop: true,
    });

    return overlayConfig;
  }

  closeWithResult(result: ModalCloseResult): void {
    this.onClose$.next(result);
  }

  // делает похожее что и closeWithResult. Добавлен чтобы разделить логику
  // после добавления компонента ModalActionsComponent
  closeWithAction(result: ModalCloseResultAction): void {
    this.onCloseWithAction$.next(result);
  }

  close(): void {
    if (this.nestedOverlayRef) {
      this.nestedOverlayRef.dispose();
      this.nestedOverlayRef = null;
      return;
    }
    if (this.overlayRef) {
      this.overlayRef.dispose();
      return;
    }
  }
}
