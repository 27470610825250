import { InjectionToken } from '@angular/core';
import { FormControl } from '@angular/forms';
import { DateRange } from '@models/modals';

export const OVERLAY_DIALOG_DATA = new InjectionToken('Overlay dialog data');
export const OVERLAY_DIALOG_CONTROL = new InjectionToken(
  'Overlay dialog control',
);

export interface OverlayData<T = any> {
  withTime?: boolean;
  timeOnly?: boolean;
  id?: string;
  control?: FormControl;
  range?: DateRange;
  rangeAllowed?: boolean;
  inputName?: string;
  dateValue?: any;
  controlData?: { name: string; value: any; minDate?: Date; maxDate?: Date };
  data?: T;
}
